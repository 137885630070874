
import Vue, { PropType } from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';

import { Services } from '@/injectables/tokens';
import { OutputServiceContract } from '@/injectables';

import Slide from '@/components/Output/Slide.vue';
import { SlideVisibility } from '@/app/graphql';

export default Vue.extend({
  name: 'SlideList',

  components: {
    Slide,
  },

  useInjectable: [Services.Output],
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
    },
    slides: {
      type: Array as PropType<OutputSlide[]>,
      required: true,
    },
  },

  computed: {
    isChangeDisabled(): boolean {
      return (
        !this.isManageLayouts &&
        this.$store.state.newProposal.newProposal?.status &&
        (this.outputService as OutputServiceContract).proposalOutputIsChangeDisabled({
          proposalStatus: this.$store.state.newProposal.newProposal.status,
        })
      );
    },

    isManageLayouts(): boolean {
      return ['manageLayouts', 'manageProductSlides'].includes(this.$route.name);
    },
  },

  methods: {
    slideIsHidden(slide: OutputSlide): boolean {
      return slide?.visibility === SlideVisibility.Hidden;
    },
    onElementObserved(entries: IntersectionObserverEntry[]): void {
      if (this.$store.state.output.isScrollingByClick) return;
      const shouldBeActive = entries.find(entry => entry.isIntersecting);
      if (shouldBeActive) {
        const shouldBeActiveId = shouldBeActive.target.getAttribute('data-id');
        if (shouldBeActiveId && this.$store.state.output.activeSlideId !== shouldBeActiveId) {
          this.$store
            .dispatch('output/updateActiveSlide', shouldBeActiveId)
            .then(() => {
              if (this.$refs['output-sidebar']?.$el?.querySelector('div.v-navigation-drawer__content')) {
                this.$vuetify.goTo(`#prev_${shouldBeActiveId}`, {
                  container: this.$refs['output-sidebar'].$el.querySelector('div.v-navigation-drawer__content'),
                });
              }
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.log('Output - updateActiveSlide', err);
            });
        }
      }
    },
    isEmptySlide(slide): boolean {
      return !this.fullScreen && this.$store.getters['output/isEmptySlide'](slide);
    },
  },

  created(): void {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      threshold: 0.55,
    });
  },

  beforeDestroy(): void {
    this.observer.disconnect();
  },
});
