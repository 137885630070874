
import Vue from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';

import { Services } from '@/injectables/tokens';
import { OutputServiceContract } from '@/injectables';
import { Routes } from '@/router/routes';

import OutputEditToolbar from '@/components/Output/OutputEditToolbar.vue';

import OutputContainer from '@/entities/output/output-container.vue';

import OutputMainActionsBtn from '@/features/output/output-main-actions.vue';
import SlideList from '@/features/output/slide-list.vue';
import { SlideVisibility } from '@/app/graphql';

export default Vue.extend({
  name: 'SlideLibraryOutput',

  components: {
    OutputEditToolbar,
    OutputContainer,
    OutputMainActionsBtn,
    SlideList,
  },

  inject: ['$confirm'],

  useInjectable: [Services.Output],

  computed: {
    isSmallerScreen(): boolean {
      return this.$vuetify.breakpoint.width <= 1600;
    },
    outputLoading(): boolean {
      return this.$store.state.output.outputLoading;
    },
    hasUnsavedChanges(): boolean {
      return this.$store.state.output.outputHasBeenChanged;
    },
    activeSlideIsReadOnly(): boolean {
      const allSlides = this.$store.getters['output/allLocalSlides'];
      const activeSlide = allSlides.find(slide => slide._id === this.$store.state.output.activeSlideId);

      return activeSlide?.readonly || false;
    },
    isChangeDisabled(): boolean {
      return !this.isManageLayouts;
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAdmin'];
    },
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    slideId(): string {
      const { slideId = '' } = this.$route.params;
      return slideId;
    },
    agencyId(): string {
      const { agency = '' } = this.$route.params;
      return agency;
    },
    defaultFont(): string {
      return (this.outputService as OutputServiceContract).defaultOutputFontFamily(this.userAgency);
    },
    userAgency(): string {
      return this.$store.state.auth.user.Agency;
    },
    loading(): boolean {
      return this.$store.state.output.getOutputLoading || this.$store.state.output.outputLoading;
    },
    slides(): OutputSlide[] {
      return this.$store.getters['output/allLocalSlides'].filter(
        slide => slide?.visibility !== SlideVisibility.Deleted,
      );
    },
    isManageLayouts() {
      return true;
    },
    defaultLayout(): string {
      return this.$route.query.layout;
    },
  },

  methods: {
    async closeConfirmation() {
      return (
        await this.$confirm.show({
          title: 'Unsaved changes',
          body: 'Are you sure you want to leave without saving?',
          confirmText: 'Leave',
          cancelText: 'Stay',
        })
      ).confirmed;
    },
    saveOutput(): void {
      this.$store.dispatch('output/updateBlankSlide', {
        slides: this.slides,
        slideId: this.slideId,
      });
    },
    async handleCloseOutputEditor(): Promise<void> {
      if (this.hasUnsavedChanges && !(await this.closeConfirmation())) {
        return;
      }
      if (this.hasUnsavedChanges) {
        this.$store.dispatch('output/clearOutputHasChanged');
      }
      if (window.history.length > 2) {
        return this.$router.go(-1);
      }
      this.$router.push({ name: Routes.Home });
    },
    isEmptySlide(slide): boolean {
      return this.showSidebar && this.$store.getters['output/isEmptySlide'](slide);
    },
    setLocalOutput(): void {
      this.$store.dispatch('output/cloneOutput');
    },
    getOutputData({ layoutObj }: { layoutObj: { layout: string } }): void {
      this.$store.dispatch('output/getLayoutAdditionalInfo', { ...layoutObj });
    },
  },

  mounted(): void {
    this.$store.dispatch('output/fetchBlankSlide', { slideId: this.slideId, agencyId: this.agencyId });
    this.getOutputData({ layoutObj: { layout: this.defaultLayout } });
  },

  async beforeRouteLeave(to, from, next): Promise<void> {
    this.$store.dispatch('output/resetOutputAndProposal', {
      routeName: to.name,
    });
    next();
  },
});
